import './style.css';
import song from './metadata/music.mp3';
import backdropImg from './metadata/wallhaven-l35jjp.jpg';
import bannerImg from './metadata/bannerLegends1.png';
import pfpImg from './metadata/pfp.png';
import dogeImg from './metadata/flying-doge.gif';
import harambeImg from './metadata/flying-harambe.gif';
import grumpyImg from './metadata/flying-grumpy.gif';
import cheemsImg from './metadata/flying-cheems.gif';
import sadCatImg from './metadata/flying-sadpianocat.gif';

import folderHandsEmoji from './metadata/folded-hands_1f64f.png';
import cryingFaceEmoji from './metadata/loudly-crying-face_1f62d.png';
import palmsUpEmoji from './metadata/palms-up-together_1f932.png';

const landingPage = document.getElementById("landingPage");

const banner = document.createElement('img');
banner.classList.add("banner");
banner.src = bannerImg;
landingPage.appendChild(banner);

const landingBackdrop = document.createElement('img');
landingBackdrop.id = "landBackdrop";
landingBackdrop.src = backdropImg;
landingPage.appendChild(landingBackdrop);

const closeLandingBtn = document.createElement('button');
closeLandingBtn.id = "closeLandingBtn";
closeLandingBtn.classList.add("backBlur");
closeLandingBtn.innerHTML = `ENTER HEAVEN`;
landingPage.appendChild(closeLandingBtn);

const mainPage = document.getElementById("mainPage");

const backdrop = document.createElement('img');
backdrop.id = "backdrop";
backdrop.src = backdropImg;
mainPage.appendChild(backdrop);

let currentMemeIndex = 0;
function flyingMemes() {
  const doge = document.createElement('img');
  doge.src = dogeImg;
  const harambe = document.createElement('img');
  harambe.src = harambeImg;
  const grumpy = document.createElement('img');
  grumpy.src = grumpyImg;
  const cheems = document.createElement('img');
  cheems.src = cheemsImg;
  const sadCat = document.createElement('img');
  sadCat.src = sadCatImg;
  const collection = [doge, harambe, grumpy, cheems, sadCat];
  const selectedMeme = collection[currentMemeIndex];
  selectedMeme.classList.add("flyingMeme");
  main.appendChild(selectedMeme);
  currentMemeIndex = (currentMemeIndex + 1) % collection.length;
}

const main = document.querySelector('main');

const folderHands = document.createElement('img');
folderHands.classList.add("emoji");
folderHands.src = folderHandsEmoji;
const cryingFace = document.createElement('img');
cryingFace.classList.add("emoji");
cryingFace.src = cryingFaceEmoji;
const palmsUp = document.createElement('img');
palmsUp.classList.add("emoji");
palmsUp.src = palmsUpEmoji;

const headerText = document.createElement('span');
headerText.classList.add("header");
headerText.innerHTML = `&nbsp;RIP $LEGENDS&nbsp;`;
headerText.insertBefore(palmsUp, headerText.firstChild);
headerText.appendChild(folderHands);
headerText.appendChild(cryingFace);
main.appendChild(headerText);

const pfp = document.createElement('img');
pfp.classList.add("pfpImg");
pfp.src = pfpImg;
main.appendChild(pfp);

const actions = document.createElement('div');
actions.classList.add("actions");

const telegramBtn = document.createElement('button');
telegramBtn.id = "telegram-button";
telegramBtn.classList.add("backBlur");
telegramBtn.innerHTML = `Telegram`;
actions.appendChild(telegramBtn);

const twitterBtn = document.createElement('button');
twitterBtn.id = "twitter-button";
twitterBtn.classList.add("backBlur");
twitterBtn.innerHTML = `Twitter`;
actions.appendChild(twitterBtn);

const chartBtn = document.createElement('button');
chartBtn.id = "chart-button";
chartBtn.classList.add("backBlur");
chartBtn.innerHTML = `Chart`;
actions.appendChild(chartBtn);

const buyBtn = document.createElement('button');
buyBtn.id = "buy-button";
buyBtn.classList.add("backBlur");
buyBtn.innerHTML = `Buy $LEGENDS`;
actions.appendChild(buyBtn); 

main.appendChild(actions);
mainPage.appendChild(main);

const footer = document.querySelector('footer');
footer.classList.add("backBlur");

const contract = document.createElement('span')
contract.innerHTML = `6mdmLRCHpEzZ2tbULbzzjRbqgZ1WPZg2vjnA8VWiaxjd`;
footer.appendChild(contract);

const copyCaBtn = document.createElement('button');
copyCaBtn.innerHTML = `Copy`;
footer.appendChild(copyCaBtn);

mainPage.appendChild(footer);

document.addEventListener('DOMContentLoaded', function() {
  const audio = new Audio(song);
  audio.loop = true;
  audio.volume = 1.0;

  landingPage.style.display = 'flex';
  mainPage.style.display = 'none';

  setInterval(flyingMemes, 2000);

  closeLandingBtn.addEventListener('click', function() {
    audio.play().catch(error => {
      console.log("Autoplay was prevented. Please interact with the page to play audio.", error);
    });
    
    landingPage.style.display = 'none'
    mainPage.style.display = 'flex';
  });

  // LINKS
  twitterBtn.addEventListener('click', function() {
    window.open('https://x.com/LegendsOnSolana', '_blank');
  });
  telegramBtn.addEventListener('click', function() {
    window.open('https://t.me/LegendsOnSolana', '_blank');
  });
  chartBtn.addEventListener('click', function() {
    window.open('https://pump.fun/6mdmLRCHpEzZ2tbULbzzjRbqgZ1WPZg2vjnA8VWiaxjd', '_blank');
  });
  buyBtn.addEventListener('click', function() {
    window.open('https://pump.fun/6mdmLRCHpEzZ2tbULbzzjRbqgZ1WPZg2vjnA8VWiaxjd', '_blank');
  });

  // COPY LOGIC
  const textToCopy = contract.innerHTML;
  copyCaBtn.addEventListener("click", async () => {
    try {
      // Try the modern Clipboard API first (if supported)
      await navigator.clipboard.writeText(textToCopy);
      console.log("Text copied successfully using Clipboard API");
      alert("Contract copied successfully!");
    } catch (err) {
      // If Clipboard API fails, use the legacy approach
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      textArea.style.position = "fixed"; // Hide element off-screen
      textArea.style.left = "-9999px";
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      console.log("Text copied successfully using legacy approach");
      alert("Contract copied successfully!");
    }
  });

});